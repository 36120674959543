class WebSocketManager {
  constructor(socket) {
    if (!WebSocketManager.instance) {
      this.socket = socket;
      this.listeners = {};
      this.listenersName = {};

      const events = [
        'message', 'chat_message', 'ai_chat_message',
        'whatsapp_chat_message', 'whatsapp_notification',
        'ai_notification_message', 'whatsapp_notification_message',
        'people_dropped', 'video_tile_message'
      ];

      events.forEach(event => {
        this.socket?.on(event, (message) => {
          this.emit(event, message);
        });
      });

      WebSocketManager.instance = this;
    }

    return WebSocketManager.instance;
  }

  on(event, listener, listenerName) {
    let flag = false;

    if (!this.listeners[event]) {
      this.listeners[event] = [];
      this.listenersName[event] = [];
    }

    this.listenersName[event].forEach((functionName) => {
      if(functionName === listenerName) {
        flag = true;
      }
    });

    if (!flag) {
      this.listeners[event].push(listener);
      this.listenersName[event].push(listenerName);
    }
  }

  emit(event, data) {
    if (this.listeners[event]) {
      this.listeners[event].forEach((listener) => listener(data));
    }
  }

  // method for removing listeners if component ummounted
  off(event, listener, listenerName) {
    if (this.listeners[event]) {
      const index = this.listenersName[event].indexOf(listenerName);

      if (index > -1) {
        // Remove the listener and its name from both arrays
        this.listeners[event].splice(index, 1);
        this.listenersName[event].splice(index, 1);
      }
    }
  }

  disconnect() {
    this.socket.disconnect();
  }
}

export default WebSocketManager;